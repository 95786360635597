import clsx from "clsx"
import React from "react"
import styles from "./QuizAnswerButton.module.css"

interface Props {
  title?: string
  onClick?: (() => void) | ((e: React.MouseEvent<HTMLButtonElement>) => void)
  isLoading?: boolean
  isActive?: boolean
  children?: React.ReactNode
  iconLeft?: React.ReactNode
  iconRight?: React.ReactNode
  className?: string
  dataCy?: string
  type?: "button" | "submit" | "reset"
  disabled?: boolean
  id?: string
}

export const QuizAnserButton = ({
  title,
  isLoading,
  type,
  isActive = true,
  iconLeft,
  iconRight,
  className = "",
  onClick,
  children = null,
  dataCy,
  ...rest
}: Props) => {
  const disabled = rest.disabled || isLoading

  const buttonClasses: string[] = [styles.button, styles.rectangular, className]

  if (isActive) {
    buttonClasses.push(styles.active)
  }

  if (!disabled) {
    buttonClasses.push(styles.white)
  }

  return (
    <button
      className={clsx(buttonClasses)}
      onClick={disabled || !onClick ? null : onClick}
      data-cy={dataCy}
      {...rest}
      type={type}
    >
      {iconLeft}
      {title ? <span>{title}</span> : children} {iconRight}
      {isLoading ? (
        <span className={styles.loadingContainer}>
          <div className={styles.loader} />
        </span>
      ) : null}
    </button>
  )
}
