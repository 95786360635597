/* eslint-disable security/detect-object-injection */
import React from "react"
import { captureException } from "@sentry/nextjs"
import clsx from "clsx"
import styles from "./Button.module.css"

export type ButtonSize = "slim" | "default" | "large"

interface ButtonProps {
  type: "primary" | "secondary" | "outline" | "text" | "plain" | "monochrome" | "marketing"
  shape: "square" | "round"
  size: ButtonSize
  title?: string | JSX.Element
  onClick?: (() => void) | ((e: React.MouseEvent<HTMLButtonElement>) => void)
  loading?: boolean
  disabled?: boolean
  danger?: boolean
  className?: string
  iconLeft?: React.ReactNode
  iconRight?: React.ReactNode
  upperCase?: boolean
  dataCy?: string
  id?: string
  style?: React.CSSProperties
}

const getButtonClass = (props: ButtonProps): string => {
  const { danger, shape, size, type, className, upperCase, title, iconLeft, iconRight } = props
  const classes = [styles.button, className]

  classes.push(styles[shape])

  if (upperCase) {
    classes.push(styles.upperCase)
  }

  if (title && (iconLeft || iconRight)) {
    classes.push(styles.iconAndText)
  }

  if (!props.title && (props.iconLeft || props.iconRight)) {
    classes.push(styles[size + "Icon"])

    if (props.disabled && props.type === "text") {
      classes.push(styles.disabledText)
    }
  } else {
    classes.push(styles[size])
  }

  if (danger) {
    switch (type) {
      case "primary":
        classes.push(styles.dangerPrimary)
        break
      case "outline":
        classes.push(styles.dangerOutline)
        break
      default:
        captureException(new Error(`Danger button can only be primary or outline. Received ${props.type}`))
        classes.push(styles.dangerPrimary)
    }
  } else {
    classes.push(styles[type])
  }

  return clsx(classes)
}

export const Button = (props: ButtonProps) => {
  const { disabled, loading, onClick, iconLeft, iconRight, title, dataCy, id } = props
  const buttonClass = getButtonClass(props)

  const onClickInner = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (loading || disabled) {
      e.preventDefault()
      e.stopPropagation()
    } else {
      onClick(e)
    }
  }

  return (
    <button
      className={buttonClass}
      disabled={disabled}
      onClick={onClickInner}
      data-cy={dataCy}
      id={id}
      style={props.style}
    >
      {iconLeft ? iconLeft : null}
      {loading ? (
        <span className={styles.loadingContainer}>
          <div className={styles.loader} />
        </span>
      ) : null}
      {title ?? null}
      {iconRight ? iconRight : null}
    </button>
  )
}
