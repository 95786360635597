import React from "react"
import styles from "./CallToActionButton.module.css"

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  title: string
  isLoading?: boolean
  isActive?: boolean
  className?: string
}

export const CallToActionButton = ({ title, isLoading = false, isActive = true, className = "", ...rest }: Props) => {
  const disabled = rest.disabled || isLoading

  const style = [styles.button]

  if (isActive) {
    style.push(styles.active)
  }

  if (disabled) {
    style.push(styles.disabled)
  }

  style.push(className)

  return (
    <button type="submit" className={style.join(" ")} {...rest}>
      {title}
      {isLoading ? (
        <span className={styles.loadingContainer}>
          <div className={styles.loader} />
        </span>
      ) : null}
    </button>
  )
}
