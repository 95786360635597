import clsx from "clsx"
import { useIsMobile } from "helpers/hooks/mediaQueryHook"
import { formatCurrency, formatDoubleDecimal } from "helpers/formatHelper"
import styles from "./LegendEntry.module.css"

interface LegendEntryProps {
  title: string
  value: number
  color?: string
  width: number
  label?: string
  minWidth?: number
  format?: "notional" | "fractional"
}

export const LegendEntry = (props: LegendEntryProps) => {
  const isMobile = useIsMobile()
  return (
    <div className={styles.legendEntryWrapper} style={{ width: `${props.width}%`, minWidth: props.minWidth }}>
      {props.color && <div className={styles.legendMarker} style={{ backgroundColor: props.color }}></div>}
      <div>
        <div className={styles.legendTitle}>{props.title}</div>
        <div className={clsx(styles.legendValue)} data-cy={`${props.title}-legend-value`}>
          <strong>
            {props.format === "notional"
              ? formatCurrency(props.value, false)
              : `${formatDoubleDecimal(props.value, false)} shares `}
          </strong>
          {props.label && !isMobile ? `(${props.label})` : null}
        </div>
      </div>
    </div>
  )
}
